/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired


				var jsFeatures = function() {

					$('.banner').affix({
					  offset: 100
					});

					$('.navbar-toggle').click( function (e) {
						e.preventDefault();
					});

					$('body').scrollspy({ target: '.navbar-collapse', offset: 128, refresh: true });
					// $('.navbar-collapse').localScroll({ hash: true, offset: { left: 0, top: -122 }, reset: true });

					$('.navbar a[href^="#"]').on('click', function() {
						$theHREF = $(this).attr('href');
						$("html, body").animate({ scrollTop: $($theHREF).offset().top - 122 }, 1000);
						// console.log($theHREF);
					});

					if(window.location.hash) {
						$("html, body").animate({ scrollTop: $(window.location.hash).offset().top - 122 }, 1000);
					}


					$.contactButtons({
						effect  : '', // 'slide-on-scroll',
						buttons : {
							'phone':      { class: 'phone',    			use: useCourtneyPhoneNumber, link: ptmPhoneNumber, extras: 'data-label="' + ptmPhoneNumber + '"' },
							'email':      { class: 'email tender',  use: useTenderEmail, link: tenderEmail, extras: 'data-label="Tender Services"' },
							'emailR':     { class: 'email recruit', use: useReruitEmail, link: 'mailto:' + recruitEmail, icon: 'envelope', extras: 'data-label="Recruitment"' },
							'facebook':   { class: 'facebook', 			use: useCourtneyFB, link: ptmFB, extras: 'target="_blank" data-label="Like Us on Facebook"' },
							'twitter':    { class: 'twitter', 			use: useCourtneyTwitter, link: ptmTwitter, extras: 'target="_blank" data-label="Follow Us on Twitter"' }
						}
					});


					$('.tabbed-section-tabs').tabCollapse({
						tabsClass: 'hidden-sm hidden-xs',
						accordionClass: 'visible-sm visible-xs'
					});


					$('.slick-carousel').slick();



					// "Lazy-load" posts
					$('[href="#load-more-posts"]').on('click', function(e) {
						var link = $(this);
						rowsOfPostsToShow++;

						$('.latest-news-posts .row-' + rowsOfPostsToShow).slideDown(350, function() {
							if ( totalPosts <= rowsOfPostsToShow * 3 ) {
								link.fadeOut();
							}
						});

						e.preventDefault();
					});



					$('.modal .post-modal').on('show.bs.modal', function(e) {
						var nextModal = e.relatedTarget.href,
							splitURL		= nextModal.split('#');

						if ( $('#' + splitURL[1]).parent('.row').is(':hidden') ) {
							rowsOfPostsToShow++;
							$(this).parent().slideDown(350, function() {
								if ( totalPosts <= rowsOfPostsToShow * 3 ) {
									$('[href="#load-more-posts"]').fadeOut();
								}
							});
						}
					});





					$(document).on({
						mouseenter: function () {
							$(this).find('.cell-overlay').stop().animate({ 'top' : 0 }, 300, function (){ //slideDown(300);
								$(this).find('.overlay-content-main').fadeIn();
							});
						},
						mouseleave: function () {
							$(this).find('.overlay-content-main').hide();
							$(this).find('.cell-overlay').stop().css({ 'top' : 'auto' });
							//.slideUp(200);
						}
					}, '.latest-news-posts .cell-with-overlay');












					/**
					 * Coderra functions
					 * author: PR@PixelTree
					 */
					if ( $('.sameheight').length > 0 ) {

						boxes = $('.sameheight');
						maxHeight = Math.max.apply(
							Math, boxes.map(function() {
								return $(this).height();
							}
						).get());
						boxes.height(maxHeight);
					}



					if ( $('.gmw-posts-wrapper').length > 0 ) {
						// jQuery("body").addClass("recruitment");


						$(document).on({ //on click of the button
							click: function() {


								var title = $(this).attr('dataTitle'); //put the current dataTitle in the variable title
								var jobref = $(this).attr("rel");
								$(".job-applied-for input").val( title); //put the title as the job in your contact form 7
								$('.job-applied-for input').attr('readonly', true); //make the job readonly
								$(".job-code input").val(jobref); //put the title as the job in your contact form 7
								$('.job-code input').attr('readonly', true); //make the job readonly
							}
						}, '.applynow');
					
						var appendthis =  ("<div class='modal-overlay js-modal-close'></div>");
					
						$(document).on({
							click: function(e) {
								e.preventDefault();

								$("body").append(appendthis);
								$("body").addClass("modal-open");
								$(".modal-overlay").fadeTo(500, 0.7);
								//$(".js-modalbox").fadeIn(500);
								var modalBox = $(this).attr('data-modal-id');
								$(document).find('#'+modalBox).fadeIn($(this).data());
								var post_url = $(this).attr("rel");
								var post_id = $(this).attr("rel");


								$("#tabs").html('<div class="tabinner"><div class="loading">Loading...<br/><br/><img src="/wp-content/themes/Courtney/dist/images/ajax-loader.gif" alt="Loading Graphic" /></div></div>');
								$("#tabs").load(post_url + " .tabcontent" );
								$('#job-modal').modal('show');
								$('#tabs').show();
								// return false;
							}
						}, 'a[data-modal-id]');
						
						
						$(".js-modal-close, .modal-overlay").click(function() {
							$(".modal-box, .modal-overlay").fadeOut(500, function() {
								$(".modal-overlay").remove();
								$("body").removeClass("modal-open");
							});
						});
						
						$(window).resize();
					}





					if ( $('#popup1').length > 0 || $('#popup3').length > 0 ) {
						$('.gmw-locator-submit').unbind('click');
						$(function() {
							$('.jobapply').click(function() { //on click of the button
								$('#hideapply').hide();
								$("#apply-box").show();
								var title = $('h2.dynamich2').html(); //put the current dataTitle in the variable title
								$(".job-applied-for input").val( title); //put the title as the job in your contact form 7
								$('.job-applied-for input').attr('readonly', true); //make the job readonly
							});
						});

						$(".js-modal-close, .modal-overlay").click(function() {
							$(".modal-box, .modal-overlay").fadeOut(500, function() {
								$('#hideapply').show();
								$("#apply-box").hide();
							});
						});
					}










				};

				jsFeatures();



































				/**
				 * Supplier Location - ACF Google Map
				 *
				 * source: https://www.advancedcustomfields.com/resources/google-map/
				 */

				/*
				*  add_marker
				*
				*  This function will add a marker to the selected Google Map
				*
				*  @type	function
				*  @date	8/11/2013
				*  @since	4.3.0
				*
				*  @param	$marker (jQuery element)
				*  @param	map (Google Map object)
				*  @return	n/a
				*/

				function add_marker( $marker, map ) {

					// var
					var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

					// create marker
					var marker = new google.maps.Marker({
						position : latlng,
						map : map
					});

					// add to array
					map.markers.push( marker );

					// if marker contains HTML, add it to an infoWindow
					if( $marker.html() ) {
						// create info window
						var infowindow = new google.maps.InfoWindow({
							content	: $marker.html()
						});

						// show info window when marker is clicked
						google.maps.event.addListener(marker, 'click', function() {
							infowindow.open( map, marker );
						});
					}
				}


				/*
				*  center_map
				*
				*  This function will center the map, showing all markers attached to this map
				*
				*  @type	function
				*  @date	8/11/2013
				*  @since	4.3.0
				*
				*  @param	map (Google Map object)
				*  @return	n/a
				*/

				function center_map( map ) {
					// vars
					var bounds = new google.maps.LatLngBounds();

					// loop through all markers and create bounds
					$.each( map.markers, function( i, marker ) {
						var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
						bounds.extend( latlng );
					});

					// only 1 marker?
					if( map.markers.length === 1 ) {
						// set center of map
					    map.setCenter( bounds.getCenter() );
					    map.setZoom( 16 );
					}
					else {
						// fit to bounds
						map.fitBounds( bounds );
					}
				}




			 /**
				* new_map
				*
				* This function will render a Google Map onto the selected jQuery element
				*
				* @type	function
				* @date	8/11/2013
				* @since	4.3.0
				*
				* @param	$el (jQuery element)
				* @return	n/a
				*/

				function new_map( $el ) {
					// var
					var $markers = $el.find('.marker'),
						args = {
							zoom :      16,
							scrollwheel: false,
							center :    new google.maps.LatLng(0, 0),
							mapTypeId	: google.maps.MapTypeId.ROADMAP
						},
						map = new google.maps.Map( $el[0], args);

					// add a markers reference
					map.markers = [];

					// add markers
					$markers.each(function () {
						add_marker( $(this), map );
					});

					// center map
					center_map( map );

					// return
					return map;
				}



				// var ACFmap = function() {
					$('.acf-map').each(function () {
						// create map
						var map = new_map( $(this) );
					});
				// };













				/*++
				// as reference: http://www.parsonsprojects.co.uk/smoothstate-wordpress/
				var $body = $('html, body'),
					content = $('#smoothState').smoothState({
					debug: true,
					prefetch: true,
					cacheLength: 3,
					anchors: '.banner a:not([href^="#"]), .header-wrapper-two-split a',
					blacklist: 'form, #popup1, #popup2',
					// forms: 'form',
					scroll: false,
					// onBefore: function($currentTarget, $container) {
					// 	var startingPageClasses = $container.context.firstChild.classList;
					// 	// pageContext = 'home';
					//
					// 	if ( undefined !== startingPageClasses ) {
					// 		// console.log(startingPageClasses);
					//
					//
					// 		if ( 0 <= $.inArray('tender', $containerClasses) ) {
					// 			pageContext = 'tender';
					// 		}
					//
					// 		if ( 0 <= $.inArray('recruitment', $containerClasses) ) {
					// 			pageContext = 'recruitment';
					// 		}
					//
					// 	}
					// },
					onEnd : {
						duration: 0, // Duration of the animations, if any.
						render: function (url, $container, $content) {
						  $body.css('cursor', 'auto');
						  $body.find('a').css('cursor', 'auto');
						  // $container.html($content);
						  // Trigger document.ready and window.load
						  $(document).ready();
						  $(window).trigger('load');
						}
					},
					onReady: {
						duration: 1000,
						render: function( $container, $newContent ) {
							$container.html($newContent);

							// console.log(pageContext);

							// console.log($container);//.context.firstChild.className);
							$containerClasses = $container.context.firstChild.classList;
							// if ( 0 <= $.inArray('home', $containerClasses) ) {
							// 	$container.addClass('fade-in');
							// }
							if ( 0 <= $.inArray('tender', $containerClasses) ) {
								$container.addClass('slide-left-in');
							}
							if ( 0 <= $.inArray('recruitment', $containerClasses) ) {
								$container.addClass('slide-right');
							}
							if ( 0 <= $.inArray('executive-recruitment', $containerClasses) ) {
								$container.addClass('slide-right');
							}
						}
					},
					onAfter: function( $container ) {
						// $container.removeClass('fade-in');
						$container.removeClass('slide-right');
						$container.removeClass('slide-left-in');

						jsFeatures();

						$('.acf-map').each(function () {
							// create map
							var map = new_map( $(this) );
						});
					}
				}).data('smoothState'); ++*/
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
        
        
        $('#btn-unsure').click(function() {
          $('#btn-unsure').toggleClass('hidden');
          $('.unsure-form').toggleClass('hidden');
        });

})(jQuery); // Fully reference jQuery after this point.

// (function($, undefined) {
//   var isFired = false;
//   var oldReady = jQuery.fn.ready;
//   $(function() {
//     isFired = true;
//     $(document).ready();
//   });
//   jQuery.fn.ready = function(fn) {
//     if(fn === undefined) {
//       $(document).trigger('_is_ready');
//       return;
//     }
//     if(isFired) {
//       window.setTimeout(fn, 1);
//     }
//     $(document).bind('_is_ready', fn);
//   };
// })(jQuery);
